.WishList {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
}

.WishList .wishlist-widget {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.WishList .wish-wrapper {
  padding-bottom: 2em;
}

.WishList .new-wish-wrapper {
  padding: 2em 54px;
}

.WishList .wishlist-wrapper {
  width: 100%;
  padding: 2em 54px 0 54px;
  overflow-y: auto;
}

.WishList .loading-wrapper {
  padding-top: 4em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}