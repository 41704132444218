@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

body .MuiInputLabel-root {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

body .MuiInputBase-input {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

body .MuiOutlinedInput-root {
  border-radius: 20px;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  background-color: #4F329A;
  background-image: linear-gradient(90deg, #4F329A, #F7B6CA);
  background-size: 100%;
  background-clip: transparent;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

h2 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-button {
  cursor: pointer
}

.text-button {
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
}

.text-button a {
  color: #8064CE;
}

.text-button a:hover {
  color: #4F329A;
}

.container {
  padding: 2em;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 40px 0px #d6d6d6;
  -moz-box-shadow: 0px 0px 40px 0px #d6d6d6;
  box-shadow: 0px 0px 40px 0px #d6d6d6;
  background: white;
}

.small-container {
  padding: 1em;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 20px 0px #d6d6d6;
  -moz-box-shadow: 0px 0px 20px 0px #d6d6d6;
  box-shadow: 0px 0px 20px 0px #d6d6d6;
  background: white;
}

.content-top {
  display: flex;
  padding: 72px 0 0 0;
}

.content-top h1 {
  font-size: 3em;
  -webkit-text-fill-color: black;
}