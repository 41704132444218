.Store {
  padding: 2em 54px;
}

.Store .details {
  margin-top: 1em;
}

.Store .items {
  padding-top: 1.5em;
}

.Store .small-container {
  cursor: pointer;
}

.Store .item-details {
  padding: 1em;
}

.Store .item-wrapper {
  display: flex;
  flex-direction: row;
}

.Store .item-cost {
  font-size: 0.8em;
  align-self: flex-end;
  color: #EB6F99;
  margin: 0 0 0.2em 0.5em;
}

.Store .purchase-button {
  margin-left: auto;
  color: #EB6F99;
}

.Store .purchase-button:hover {
  color: #4F329A;
}