.Wish {
  position: relative;
}

.Wish h3 {
  margin: 0;
  padding-right: 0.5em;
}

.Wish .wish-top {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 1em;
}

.Wish .link {
  font-size: 0.9em;
  padding-bottom: 0.1em;
  color: #BEBEBE;
}

.Wish a.link {
  padding-bottom: 0.2em;
}

.Wish a.link:hover {
  color: #8064CE;
}

.Wish .delete {
  visibility: hidden;
  position: absolute;
  right: 2em;
  top: 2em;
  color: red;
}

.Wish:hover .delete {
  visibility: visible;
}
