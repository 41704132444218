.Chat {
  display: flex;
  flex-direction: column;
}

.Chat .posts-container {
  height: 100%;
  display: flex;
  margin-bottom: 2em;
  overflow-y: auto;
  flex-direction: column-reverse;
}

.Chat .posts {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Chat .posts > * {
  flex-shrink: 0;
}

.Chat .Post:not(:first-child) {
  margin-top: 0;
}

.Chat .bottom {
  height: 3em;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 1;
}

.Chat .bottom .text-field {
  height: 100%;
  width: 100%;
}

.Chat .bottom .text-field input {
  font-size: 0.9em;
}

.Chat .bottom  .MuiOutlinedInput-root {
  border-radius: 20px;
}

.Chat .bottom .submit-button {
  margin: 0 0 0 1em;
}

.Chat .bottom .submit-icon {
  color: #8064CE;
  font-size: 20px;
}

.Chat .bottom .submit-button:hover .submit-icon {
  color: #4F329A;
}