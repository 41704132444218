.Post {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
}

.Post.mine {
  align-self: flex-end;
}

.Post .name {
  color: #F7B6CA;
  font-size: 0.8em;
  margin: 4px 12px;
}

.Post.mine .name {
  align-self: flex-end;
}

.Post .content {
  border-radius: 20px;
  padding: 0.8em 1em;
  background: #f5f5f5;
  font-size: 0.9em;
  width: fit-content;
}