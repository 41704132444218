.Home {
  overflow-y: auto;
  overflow-x: hidden;
}

.Home .header-text {
  width: fit-content;
  position: fixed;
  z-index: 3;
  left: 72px;
  top: 120px;
}

.Home .header-text h1 {
  font-size: 6em;
  width: fit-content;
  margin: 0;
  margin-bottom: 0.1em;
}

.Home .header-welcome {
  -webkit-text-fill-color: black;
}

.Home .page {
  height: 100vh;
}

.Home .page1 {
  padding-top: 1px;
  height: 95vh;
}

.Home .page1 .Memories {
  margin: 418px 0 0 72px;
}

.Home .page1 .announcement {
  position: absolute;
  top: 120px;
  right: 72px;
}

.Home .page1 .Announcement {
  width: 35em;
}

.Home .page2 {
  background-color: #F5F5F5;
  display: flex;
  justify-content: space-between;
}

.Home .page2 .introduction {
  margin: 400px 0 0 90px;
}

.Home .page2 .introduction a {
  font-size: 20px;
  margin-right: 2em;
}

.Home .page2 .introduction .instagram-icon{
  color: #8064CE;
}

.Home .page2 .introduction .youtube-icon{
  color: red;
}

.Home .page2 .introduction .github-icon{
  color: black;
}

.Home .bounce-container {
  margin-top: 120px;
}

.Home .page2 .Chat {
  margin-right: 72px;
  width: 35em;
  height: 40em; 
  max-height: 60vh;
}