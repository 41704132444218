.auth-top {
  height: 100vh;
  display: flex;
}

.auth-top .text-button {
  margin-bottom: 2em;
}

.auth-container {
  margin: auto;
  margin-top: 10%;
  width: 20em;

  display: flex;
  flex-direction: column;

  text-align: center;
}

.auth-container form {
  display: grid;
  row-gap: 1em;
}

.auth-container form .submit-button {
  margin-top: 1em;
  width: 100%;
  align-self: flex-end;
  place-self: center;
  --button-default-border-radius: 20px;
}

.auth-container form .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #8064CE;
}

.auth-container form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4F329A;
}

.auth-container form .MuiFormLabel-root {
  font-size: 0.9rem;
}

.auth-container form .MuiFormLabel-root.Mui-focused {
  color: #4F329A;
}
