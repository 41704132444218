.StoreList {
  padding: 0 54px;
  display: flex;
  flex-direction: row;
}

.StoreList .StoreCard {
  margin-right: 2em;
}

.StoreList .StoreCard:last-child {
  margin-right: 0em;
}