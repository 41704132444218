@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap);
.auth-top {
  height: 100vh;
  display: flex;
}

.auth-top .text-button {
  margin-bottom: 2em;
}

.auth-container {
  margin: auto;
  margin-top: 10%;
  width: 20em;

  display: flex;
  flex-direction: column;

  text-align: center;
}

.auth-container form {
  display: grid;
  grid-row-gap: 1em;
  row-gap: 1em;
}

.auth-container form .submit-button {
  margin-top: 1em;
  width: 100%;
  align-self: flex-end;
  place-self: center;
  --button-default-border-radius: 20px;
}

.auth-container form .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #8064CE;
}

.auth-container form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4F329A;
}

.auth-container form .MuiFormLabel-root {
  font-size: 0.9rem;
}

.auth-container form .MuiFormLabel-root.Mui-focused {
  color: #4F329A;
}

.Logout .log-out-button {
  width: 100%;
}
.Nav {
  padding: 36px 36px 0 36px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;

  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 5;
}

.Nav .nav-button {
  padding: 10px;
  border-radius: 50%;
}

.Nav .nav-margin {
  margin-right: 26px;
}

.Nav .nav-button:hover .nav-icon {
  color: #4F329A;
}

.Nav .Logout.nav-button:hover .nav-icon {
  color: red;
}

.Nav .nav-icon {
  color: #F7B6CA;
}

.Nav .Logout {
  margin-left: auto;
}

.Nav .nav-icon.fc {
  color: #F60E26;
}

.Nav .nav-button:hover .nav-icon.fc {
  color: #F6D003;
}

.Memory {
  border: 5px solid #F1f1f1;
  background-color: #F1f1f1;
  border-radius: 5px;
  box-sizing: border-box;
}

.Memory .memory {
  height: 300px;
  width: 300px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.Memory .memory img {
  -webkit-user-drag: none;
  height: 300px;
  object-fit: cover;
}


.Post {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
}

.Post.mine {
  align-self: flex-end;
}

.Post .name {
  color: #F7B6CA;
  font-size: 0.8em;
  margin: 4px 12px;
}

.Post.mine .name {
  align-self: flex-end;
}

.Post .content {
  border-radius: 20px;
  padding: 0.8em 1em;
  background: #f5f5f5;
  font-size: 0.9em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.Chat {
  display: flex;
  flex-direction: column;
}

.Chat .posts-container {
  height: 100%;
  display: flex;
  margin-bottom: 2em;
  overflow-y: auto;
  flex-direction: column-reverse;
}

.Chat .posts {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Chat .posts > * {
  flex-shrink: 0;
}

.Chat .Post:not(:first-child) {
  margin-top: 0;
}

.Chat .bottom {
  height: 3em;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 1;
}

.Chat .bottom .text-field {
  height: 100%;
  width: 100%;
}

.Chat .bottom .text-field input {
  font-size: 0.9em;
}

.Chat .bottom  .MuiOutlinedInput-root {
  border-radius: 20px;
}

.Chat .bottom .submit-button {
  margin: 0 0 0 1em;
}

.Chat .bottom .submit-icon {
  color: #8064CE;
  font-size: 20px;
}

.Chat .bottom .submit-button:hover .submit-icon {
  color: #4F329A;
}
.Announcement .container-sub-element {
  margin-top: 1em;
  margin-left: 1em;
}

.Announcement .container-element {
  margin-top: 2em;
}
.Home {
  overflow-y: auto;
  overflow-x: hidden;
}

.Home .header-text {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  z-index: 3;
  left: 72px;
  top: 120px;
}

.Home .header-text h1 {
  font-size: 6em;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  margin-bottom: 0.1em;
}

.Home .header-welcome {
  -webkit-text-fill-color: black;
}

.Home .page {
  height: 100vh;
}

.Home .page1 {
  padding-top: 1px;
  height: 95vh;
}

.Home .page1 .Memories {
  margin: 418px 0 0 72px;
}

.Home .page1 .announcement {
  position: absolute;
  top: 120px;
  right: 72px;
}

.Home .page1 .Announcement {
  width: 35em;
}

.Home .page2 {
  background-color: #F5F5F5;
  display: flex;
  justify-content: space-between;
}

.Home .page2 .introduction {
  margin: 400px 0 0 90px;
}

.Home .page2 .introduction a {
  font-size: 20px;
  margin-right: 2em;
}

.Home .page2 .introduction .instagram-icon{
  color: #8064CE;
}

.Home .page2 .introduction .youtube-icon{
  color: red;
}

.Home .page2 .introduction .github-icon{
  color: black;
}

.Home .bounce-container {
  margin-top: 120px;
}

.Home .page2 .Chat {
  margin-right: 72px;
  width: 35em;
  height: 40em; 
  max-height: 60vh;
}
.StoreCard {
  text-align: center;
  cursor: pointer;
}

.StoreCard .name {
  width: 5em;
  margin-bottom: 0;
}
.StoreList {
  padding: 0 54px;
  display: flex;
  flex-direction: row;
}

.StoreList .StoreCard {
  margin-right: 2em;
}

.StoreList .StoreCard:last-child {
  margin-right: 0em;
}
.Store {
  padding: 2em 54px;
}

.Store .details {
  margin-top: 1em;
}

.Store .items {
  padding-top: 1.5em;
}

.Store .small-container {
  cursor: pointer;
}

.Store .item-details {
  padding: 1em;
}

.Store .item-wrapper {
  display: flex;
  flex-direction: row;
}

.Store .item-cost {
  font-size: 0.8em;
  align-self: flex-end;
  color: #EB6F99;
  margin: 0 0 0.2em 0.5em;
}

.Store .purchase-button {
  margin-left: auto;
  color: #EB6F99;
}

.Store .purchase-button:hover {
  color: #4F329A;
}
.Stores {
  display: flex;
  flex-direction: column;
  padding: 72px 0 0 54px;
}

.Stores h1 {
  font-size: 3em;
  -webkit-text-fill-color: black;
}

.Stores .stores-top {
  padding: 0 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Stores .balance-wrapper {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  padding-bottom: 3em;
}

.Stores .balance-icon {
  margin-right: 0.5em;
}

.Stores .balance-number {
  color: #4F329A;
}
.Prizes {
  z-index: 6;
  flex-direction: column;
  background-color: #222222;
  box-sizing: border-box;
  height: 100vh;
  align-items: center;
}

.Prizes.content-top h1 {
  text-align: center;  
  
  background-color: red;
  background-image: linear-gradient(90deg, red, white);
  background-size: 100%;
  background-clip: transparent;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.Prizes .aws-btn {
  margin: 2em 1em;
  --button-primary-color: #ff0000;
  --button-primary-color-dark: #5c492d;
  --button-primary-color-light: #d4d9e4;
  --button-primary-color-hover: #da0101;
  --button-primary-border: none;
  --button-secondary-color: #ffffff;
  --button-secondary-color-dark: #8c8c8c;
  --button-secondary-color-light: #cc0000;
  --button-secondary-color-hover: #dbdbdb;
  --button-secondary-border: none;
}

.Prizes .side {
  position: absolute;
  top: 10em;
}

.Prizes .left {
  left: 4em;
}

.Prizes .right {
  right: 4em;
}
.Wish {
  position: relative;
}

.Wish h3 {
  margin: 0;
  padding-right: 0.5em;
}

.Wish .wish-top {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 1em;
}

.Wish .link {
  font-size: 0.9em;
  padding-bottom: 0.1em;
  color: #BEBEBE;
}

.Wish a.link {
  padding-bottom: 0.2em;
}

.Wish a.link:hover {
  color: #8064CE;
}

.Wish .delete {
  visibility: hidden;
  position: absolute;
  right: 2em;
  top: 2em;
  color: red;
}

.Wish:hover .delete {
  visibility: visible;
}

.AddWish form {
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 1em;
  row-gap: 1em;
}

.AddWish form .MuiFormLabel-root {
  font-size: 0.9rem;
}

.AddWish form textarea {
  border-radius: 20px;
  border-color: #bebebe;
  font-size: 1em;
  padding: 10.5px 14px;
}

.AddWish form .add-button {
  --button-default-border-radius: 20px;
}
.WishList {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
}

.WishList .wishlist-widget {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.WishList .wish-wrapper {
  padding-bottom: 2em;
}

.WishList .new-wish-wrapper {
  padding: 2em 54px;
}

.WishList .wishlist-wrapper {
  width: 100%;
  padding: 2em 54px 0 54px;
  overflow-y: auto;
}

.WishList .loading-wrapper {
  padding-top: 4em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Wishes {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  box-sizing: border-box;
}

.Wishes h1 {
  margin-left: 54px;
}

.Wishes .wish-members {
  display: flex;
  flex-shrink: 0;
  margin-left: 54px;
  margin-bottom: 1em;
}

.Wishes .wish-member {
  margin: 0 2em 0 0;
  cursor: pointer;
  color: #BEBEBE;
}

.Wishes .wish-member.selected{
  color: #4F329A;
  font-weight: 700;

  background-color: #4F329A;
  background-image: linear-gradient(90deg, #4F329A, #F7B6CA);
  background-size: 100%;
  background-clip: transparent;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.Wishes .wish-member:hover {
  color: #EB6F99;
}

.Wishes .wish-member.selected:hover{
  color: #4F329A;
}
.content {
  /* padding-top: 102px; */
}
body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

body .MuiInputLabel-root {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

body .MuiInputBase-input {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

body .MuiOutlinedInput-root {
  border-radius: 20px;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  background-color: #4F329A;
  background-image: linear-gradient(90deg, #4F329A, #F7B6CA);
  background-size: 100%;
  background-clip: transparent;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

h2 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-button {
  cursor: pointer
}

.text-button {
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
}

.text-button a {
  color: #8064CE;
}

.text-button a:hover {
  color: #4F329A;
}

.container {
  padding: 2em;
  border-radius: 20px;
  box-shadow: 0px 0px 40px 0px #d6d6d6;
  background: white;
}

.small-container {
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #d6d6d6;
  background: white;
}

.content-top {
  display: flex;
  padding: 72px 0 0 0;
}

.content-top h1 {
  font-size: 3em;
  -webkit-text-fill-color: black;
}
