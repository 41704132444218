.Wishes {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  box-sizing: border-box;
}

.Wishes h1 {
  margin-left: 54px;
}

.Wishes .wish-members {
  display: flex;
  flex-shrink: 0;
  margin-left: 54px;
  margin-bottom: 1em;
}

.Wishes .wish-member {
  margin: 0 2em 0 0;
  cursor: pointer;
  color: #BEBEBE;
}

.Wishes .wish-member.selected{
  color: #4F329A;
  font-weight: 700;

  background-color: #4F329A;
  background-image: linear-gradient(90deg, #4F329A, #F7B6CA);
  background-size: 100%;
  background-clip: transparent;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.Wishes .wish-member:hover {
  color: #EB6F99;
}

.Wishes .wish-member.selected:hover{
  color: #4F329A;
}