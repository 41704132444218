.AddWish form {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 1em;
}

.AddWish form .MuiFormLabel-root {
  font-size: 0.9rem;
}

.AddWish form textarea {
  border-radius: 20px;
  border-color: #bebebe;
  font-size: 1em;
  padding: 10.5px 14px;
}

.AddWish form .add-button {
  --button-default-border-radius: 20px;
}