.Nav {
  padding: 36px 36px 0 36px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;

  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 5;
}

.Nav .nav-button {
  padding: 10px;
  border-radius: 50%;
}

.Nav .nav-margin {
  margin-right: 26px;
}

.Nav .nav-button:hover .nav-icon {
  color: #4F329A;
}

.Nav .Logout.nav-button:hover .nav-icon {
  color: red;
}

.Nav .nav-icon {
  color: #F7B6CA;
}

.Nav .Logout {
  margin-left: auto;
}

.Nav .nav-icon.fc {
  color: #F60E26;
}

.Nav .nav-button:hover .nav-icon.fc {
  color: #F6D003;
}
