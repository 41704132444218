.Prizes {
  z-index: 6;
  flex-direction: column;
  background-color: #222222;
  box-sizing: border-box;
  height: 100vh;
  align-items: center;
}

.Prizes.content-top h1 {
  text-align: center;  
  
  background-color: red;
  background-image: linear-gradient(90deg, red, white);
  background-size: 100%;
  background-clip: transparent;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.Prizes .aws-btn {
  margin: 2em 1em;
  --button-primary-color: #ff0000;
  --button-primary-color-dark: #5c492d;
  --button-primary-color-light: #d4d9e4;
  --button-primary-color-hover: #da0101;
  --button-primary-border: none;
  --button-secondary-color: #ffffff;
  --button-secondary-color-dark: #8c8c8c;
  --button-secondary-color-light: #cc0000;
  --button-secondary-color-hover: #dbdbdb;
  --button-secondary-border: none;
}

.Prizes .side {
  position: absolute;
  top: 10em;
}

.Prizes .left {
  left: 4em;
}

.Prizes .right {
  right: 4em;
}