.Stores {
  display: flex;
  flex-direction: column;
  padding: 72px 0 0 54px;
}

.Stores h1 {
  font-size: 3em;
  -webkit-text-fill-color: black;
}

.Stores .stores-top {
  padding: 0 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Stores .balance-wrapper {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  padding-bottom: 3em;
}

.Stores .balance-icon {
  margin-right: 0.5em;
}

.Stores .balance-number {
  color: #4F329A;
}