.Memory {
  border: 5px solid #F1f1f1;
  background-color: #F1f1f1;
  border-radius: 5px;
  box-sizing: border-box;
}

.Memory .memory {
  height: 300px;
  width: 300px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.Memory .memory img {
  -webkit-user-drag: none;
  height: 300px;
  object-fit: cover;
}